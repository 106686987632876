import type { OfficeDataType } from '~/types/offices';
import OfficeCard from './officeCard';
import type { ReactNode } from 'react';
import { Link } from '@remix-run/react';
import { useTranslation } from 'react-i18next';

type OfficeCarouselProps = {
  offices?: OfficeDataType[];
  title?: string;
  url?: string;
  extraClasses?: string;
  children?: ReactNode;
  hiddenTitle?: boolean;
  onCardClick?: () => void;
};

const OfficeCarousel = ({
  offices,
  title,
  url = '',
  extraClasses = '',
  hiddenTitle = false,
  children,
  onCardClick = undefined,
}: OfficeCarouselProps) => {
  const { t } = useTranslation();
  if (!offices || offices?.length < 1) return null;

  return (
    <div className={extraClasses}>
      {!!title && (
        <h2 className="fo-display-2 mb-14 text-center">
          {url ? (
            <Link to={url}>
              {hiddenTitle && <span className="accessible">{t('landing.displaySections.titleSeoPrefix')}</span>} {title}
            </Link>
          ) : (
            title
          )}
        </h2>
      )}{' '}
      <div className="office-carousel invisible-scrollbar ">
        {offices?.map((o) => (
          <div
            className="office-card-wrapper"
            key={o.id}
          >
            <OfficeCard
              office={o}
              onCardClick={() => {
                if (onCardClick) onCardClick();
              }}
            />
          </div>
        ))}
      </div>
      {children && <div className="d-flex justify-content-center mt-14">{children}</div>}
    </div>
  );
};

export default OfficeCarousel;
