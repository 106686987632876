interface FAQ {
  question: string;
  answer: string;
}

interface AccordionProps {
  FAQData: FAQ[];
}

const Accordion = ({ FAQData }: AccordionProps) => {
  return (
    <div
      className="accordion accordion-flush w-100"
      id="FoAccordion"
    >
      {FAQData.map((faq, index) => (
        <div
          className="accordion-item"
          key={index}
          itemScope={true}
          itemProp="mainEntity"
          itemType="https://schema.org/Question"
        >
          <h3 className="accordion-header">
            <button
              className="fo-display-5 -medium accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target={`#collapse${index}`}
              aria-expanded="false"
              aria-controls={`collapse${index}`}
              itemProp="name"
            >
              {faq.question}
            </button>
          </h3>
          <div
            className="accordion-collapse collapse"
            id={`collapse${index}`}
            data-bs-parent="#FoAccordion"
            itemScope={true}
            itemProp="acceptedAnswer"
            itemType="https://schema.org/Answer"
          >
            <div
              className="accordion-body pt-0 pb-8"
              dangerouslySetInnerHTML={{ __html: faq.answer }}
              itemProp="text"
            ></div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Accordion;
